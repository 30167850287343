<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    en: {
    },
    pt: {
    },
    es: {
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      giftcard: null,

      buy: {
        value: 0.00,
      }
    };
  },
  methods: {
    getGiftcard() {
      api
        .get("giftcards/" + this.$route.params.slug)
        .then((response) => {
          if (response.data.status == "success") {
            this.giftcard = response.data.giftcard;
          }
        })
        .catch(error => {
          if (error) {
            this.$router.push('/giftcards');
          }
        })
    },
    buyGiftcard() {
      if (this.$route.params.slug && this.buy.value > 0) {
        api
          .post("giftcards", {
            slug: this.$route.params.slug,
            value: this.buy.value
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$router.push('/store/order/' + response.data.order.id);
            } else {
              this.$noty.error(response.data.message)
            }
          })
      }
    }
  },
  mounted() {
    this.getGiftcard();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/giftcards">Gift Cards</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block" v-if="giftcard">{{ giftcard.name }}</li>
    </ol>

    <div class="row" v-if="giftcard">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ giftcard.name }}</h4>
          <div class="page-title-right">
          </div>
        </div>
      </div>
    </div>

    <div id="giftcard" class="row" v-if="giftcard">
      <div class="col-md-6">
        <div class="giftcard-cover mb-3">
          <img class="w-100 rounded" :src="giftcard.cover" />
        </div>

        <div class="card">
          <div class="card-body">
            <div v-if="giftcard.description" class="giftcard-description mb-4">
              <h5 class="collapse-title mb-4" v-b-toggle.collapse-description><strong>Descrição</strong></h5>
              <b-collapse visible id="collapse-description">
                <div v-html="giftcard.description"></div>
              </b-collapse>
            </div>

            <div v-if="giftcard.how_to_use" class="giftcard-how_to_use mb-4">
              <hr class="pt-1">
              <h5 class="collapse-title mb-4" v-b-toggle.collapse-how_to_use><strong>Como utilizar</strong></h5>
              <b-collapse id="collapse-how_to_use">
                <div v-html="giftcard.how_to_use"></div>
              </b-collapse>
            </div>

            <div v-if="giftcard.about" class="giftcard-about mb-4">
              <hr class="pt-1">
              <h5 class="collapse-title mb-4" v-b-toggle.collapse-about><strong>Sobre</strong></h5>
              <b-collapse id="collapse-about">
                <div v-html="giftcard.about"></div>
              </b-collapse>
            </div>

            <div v-if="giftcard.cancel" class="giftcard-cancel mb-4">
              <hr class="pt-1">
              <h5 class="collapse-title mb-4" v-b-toggle.collapse-cancel><strong>Cancelamento</strong></h5>
              <b-collapse id="collapse-cancel">
                <div v-html="giftcard.cancel"></div>
              </b-collapse>
            </div>

            <div v-if="giftcard.terms" class="giftcard-terms mb-4">
              <hr class="pt-1">
              <h5 class="collapse-title mb-4" v-b-toggle.collapse-terms><strong>Termos</strong></h5>
              <b-collapse id="collapse-terms">
                <div v-html="giftcard.terms"></div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <p>Escolha o seu Gift Card</p>

        <div class="giftcard-values">
          <div class="giftcard-value" v-for="(value, index) in giftcard.value" :key="index" :class="value == buy.value ? 'value-selected' : ''" v-on:click="buy.value = value">
            <h5 class="m-0"><strong>{{ value | currency }}</strong></h5>
          </div>
        </div>

        <div class="giftcard-cart d-flex mb-4">
          <div class="d-none mr-4">
            Quantidade: 1
          </div>
          <div>
            Valor total à pagar: {{ buy.value | currency }}
          </div>
        </div>

        <button class="giftcard-buy btn btn-default btn-block btn-lg" v-on:click="buyGiftcard()" :disabled="this.buy.value == '0.00'">Comprar Agora</button>
      </div>
    </div>

  </Layout>
</template>

<style scoped>
.collapse-title {
  position: relative;
}

.collapse-title.not-collapsed:after {
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="m12 6.879-7.061 7.06 2.122 2.122L12 11.121l4.939 4.94 2.122-2.122z"></path></svg>');
  position: absolute;
  right: 0;
  top: 0;
}

.collapse-title.collapsed:after {
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M16.939 7.939 12 12.879l-4.939-4.94-2.122 2.122L12 17.121l7.061-7.06z"></path></svg>');
  position: absolute;
  right: 0;
  top: 0;
}

.giftcard-values {
  display: flex;
  flex-flow: row wrap;
  gap: 2%;
}

.giftcard-value {
  display: flex;
  flex-basis: 23.5%;
  background: #fff;
  border-radius: 8px;
  border: 2px solid #dedfe4;
  margin-bottom: 2%;
  padding: 1px;
  aspect-ratio: 2 / 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.value-selected {
  background: #1055B7;
  border-color: #1055B7;
}
.value-selected h5 {
  color: #fff;
}

@media (max-width: 2000px) {
  .giftcard-values {
    gap: 2%;
  }

  .giftcard-value {
    flex-basis: 23.5%;
  }
}

@media (max-width: 1100px) {
  .giftcard-values {
    gap: 2%;
  }

  .giftcard-value {
    flex-basis: 49%;
  }
}

.giftcard-cart {
  background: #fff;
  border: 2px solid #dedfe4;
  border-radius: 8px;
  padding: 15px;
}

.giftcard-buy {
  border-radius: 8px;
}
</style>